import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import './App.css';
import { IoMdSettings, IoMdClose } from "react-icons/io";

import * as FirestoreService from './services/firestore';

function PrayerRequest(props) {

  const [isSelected, setIsSelected] = useState(false);

  return (
    <div className={"card clickable" + (isSelected ? " bg-light border-info" : "")} onClick={() => setIsSelected(!isSelected)} >

      <div className="card-body">
        <h5 className="card-title">{props.prayerRequest.person}</h5>
        <h6 className="card-subtitle mb-2 text-muted">{new dayjs(props.prayerRequest.submittedDate).format("dddd, MMMM D")}</h6>
        <p className="card-text">{props.prayerRequest.request}</p>
      </div>

      {props.numberPrayerRequests === true &&
        <span className={"request-ordinal text-muted" + (isSelected ? " bg-light border-info" : "")}>{props.ordinal}</span>
      }
    </div>
  )
}

function PrayerRequestList(props) {
  return (
    <div className="row">
      {props.prayerRequests &&
        props.prayerRequests.map((prayerRequest, index) =>
          <div key={prayerRequest.id} className="col-md-6 col-lg-4 col-xl-3 mb-4">
            <PrayerRequest prayerRequest={prayerRequest} ordinal={index + 1} numberPrayerRequests={props.numberPrayerRequests} />
          </div>
        )}
    </div>
  )
}

function RefreshButton(props) {
  return (
    <div className="row">
      <div className="offset-md-4 col-md-4">
        <button className="btn btn-secondary btn-block" onClick={props.onRefreshRequested}>Refresh</button>
      </div>
    </div >
  )
}

function RequestPrayerForm(props) {

  function onSubmit(e) {

    e.preventDefault();

    const name = document.requestPrayerForm.name.value;
    const request = document.requestPrayerForm.request.value;

    document.requestPrayerForm.request.value = '';

    FirestoreService.createPrayerRequest(name, request)
      .then((created) => {
        if (created) {
          props.onRequestCreated();
        }
      });

    return false;
  }

  return (
    <div className="row">
      <div className="col-lg-6 col-xl-5">
        <form name="requestPrayerForm" onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="requestName">Name</label>
            <input type="text" id="requestName" name="name" className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="requestRequest">Request</label>
            <textarea id="requestRequest" name="request" className="form-control" rows="3"></textarea>
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  )
}

function App() {

  const [prayerRequests, setPrayerRequests] = useState();
  const [loaded, setLoaded] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [numberPrayerRequests, setNumberPrayerRequests] = useState(window.localStorage.getItem('numberPrayerRequests') === "true");

  function onRequestCreated() {
    setLoaded(false);
  }

  function onRefreshRequested() {
    setLoaded(false);
  }

  useEffect(() => {
    if (!loaded) {
      FirestoreService.getPrayerListItems().then(prayerRequests => {
        setPrayerRequests(prayerRequests);
        setLoaded(true);
      });
    }
  }, [loaded]);

  useEffect(() => {
    window.localStorage.setItem('numberPrayerRequests', numberPrayerRequests);
  }, [numberPrayerRequests])

  return (
    <div className="App">

      {loaded === false &&
        <div className="loading">
          <div className="loading-screen"></div>
          <img src={process.env.PUBLIC_URL + "/images/loader.svg"} alt="Loading ..." />
        </div>
      }

      <button className="btn menu-option" onClick={() => setMenuVisible(!menuVisible)}><IoMdSettings /></button>

      <header className="section">
        <div className="container">
          <h1>SCL Prayer List</h1>
        </div>
      </header>

      <article className="section section-gray">
        <div className="container">
          <h2>Requests</h2>
          <PrayerRequestList prayerRequests={prayerRequests} numberPrayerRequests={numberPrayerRequests} />
          <RefreshButton onRefreshRequested={onRefreshRequested} />
        </div>
      </article>

      <article className="section">
        <div className="container">
          <h2>Request Prayer</h2>
          <RequestPrayerForm onRequestCreated={onRequestCreated} />
        </div>
      </article>

      {menuVisible === true &&
        <div className="menu-screen" onClick={() => setMenuVisible(false)}></div>
      }

      {menuVisible === true &&
        <section className="options-menu">

          <button className="btn section-close" onClick={() => setMenuVisible(false)}><IoMdClose /></button>

          <h3>Options</h3>

          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="numberedRequestOption"
              defaultChecked={numberPrayerRequests}
              onChange={(event) => setNumberPrayerRequests(event.target.checked)} />
            <label className="custom-control-label" htmlFor="numberedRequestOption">Number Prayer Requests</label>
          </div>

        </section>
      }

    </div>
  );
}
export default App;
