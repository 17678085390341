import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import dayjs from 'dayjs';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export const authenticateAnonymously = () => {
    return firebase.auth().signInAnonymously();
};

export const getPrayerListItems = () => {

    var start = dayjs().add(-6, 'day').startOf('day').toDate();

    var sclPrayerList = db.collection(process.env.REACT_APP_FIREBASE_DB_COLLECTION)
        .where('submittedDate', '>=', start)
        .orderBy('submittedDate')
        .get()
        .then((snapshot) => {
            return snapshot.docs.map(prayerRequest => {
                var data = prayerRequest.data();
                return {
                    id: prayerRequest.id,
                    person: data.person,
                    request: data.request,
                    submittedDate: data.submittedDate.toDate()
                };
            });
        })
        .catch((error) => {
            console.error(error);
        });

    return sclPrayerList;
};

export const createPrayerRequest = (name, request) => {

    if (!request || /^\s*$/.test(request)) {
        return Promise.resolve(false);
    } else {
        return db.collection(process.env.REACT_APP_FIREBASE_DB_COLLECTION)
            .add({
                submittedDate: firebase.firestore.FieldValue.serverTimestamp(),
                person: name,
                request: request,
            })
            .then(() => {
                return true;
            });
    }
};
